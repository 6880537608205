'use client';

import dynamic from 'next/dynamic';

import { Fragment, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x-white.svg';
import { AnimatePresence, motion } from 'framer-motion';

import AlertAnimation from 'public/assets/animations/alert.json';
import WarningAnimation from 'public/assets/animations/warning.json';
import SuccessAnimation from 'public/assets/animations/success.json';
import LoadingAnimation from 'public/assets/animations/loading.json';
import Button from './Button';
import THDialogWrapper from './THDialogWrapper';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export type IAlertProps = {
    text: string;
    description?: string;
    showButton?: boolean;
    buttonText?: string;
    animation?: 'AlertAnimation' | 'WarningAnimation' | 'SuccessAnimation' | 'LoadingAnimation';
    action?: () => void | Promise<void>;
    preventClose?: boolean;
    confirmAction?: {
        buttonText: string;
        action?: () => void | Promise<void>;
    }
};

const Alert = (props: & { alert: IAlertProps | null; onHide: () => void, showAlert: boolean }) => {
    const { alert, onHide, showAlert } = props;
    const [loading, setLoading] = useState(false);

    const animationData = useMemo(() => {
        switch (alert?.animation) {
            case 'AlertAnimation':
                return AlertAnimation;
            case 'SuccessAnimation':
                return SuccessAnimation;
            case 'WarningAnimation':
                return WarningAnimation;
            case 'LoadingAnimation':
                return LoadingAnimation;
            default:
                return undefined;
        }
    }, [alert?.animation]);

    return (
        <Transition.Root show={showAlert} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-[9999999]"
                onClose={() => {
                    if (alert?.preventClose) {
                        return;
                    }
                    onHide();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity " />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-row justify-between items-center transform overflow-hidden rounded-[24px] bg-white text-left shadow-xl transition-all w-full max-w-fit h-fit">
                                {!alert?.preventClose && <button className="cursor-pointer" onClick={onHide}><XIcon className="absolute right-[20px] top-[20px] [&>path]:stroke-main" /></button>}
                                <div className="w-full">
                                    <div className="p-[40px]">
                                        <AnimatePresence mode="wait" initial>
                                            <motion.div exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                <div className="text-center space-y-[24px]">
                                                    {alert?.animation &&
                                                        <div className="w-[150px] mx-auto">
                                                            <Lottie animationData={animationData} loop={alert.animation === 'LoadingAnimation'} />
                                                        </div>
                                                    }
                                                    <div>
                                                        <h3 className="font-heading capitalize text-[48px] leading-[48px]">{alert?.text}</h3>
                                                    </div>
                                                    <div className="font-secondary flex justify-center ">
                                                        <div className="space-y-[24px] min-w-[280px] max-w-[500px]">
                                                            {alert?.description &&
                                                                <div>
                                                                    {alert?.description}
                                                                </div>
                                                            }
                                                            <div className="flex gap-4">
                                                                {alert?.showButton &&
                                                                    <Button
                                                                        fullWidth
                                                                        loading={loading}
                                                                        onClick={async () => {
                                                                            if (alert.action) {
                                                                                const res = alert.action();
                                                                                if (res) {
                                                                                    setLoading(true);
                                                                                    try {
                                                                                        await res;
                                                                                        // eslint-disable-next-line no-empty
                                                                                    } catch (error) {
                                                                                    }
                                                                                    setLoading(false);
                                                                                }
                                                                            }
                                                                            if (onHide) {
                                                                                onHide();
                                                                            }
                                                                        }}>
                                                                        {alert?.buttonText}
                                                                    </Button>
                                                                }
                                                                {alert?.confirmAction &&
                                                                    <Button
                                                                        fullWidth
                                                                        light
                                                                        loading={loading}
                                                                        onClick={async () => {
                                                                            if (alert.confirmAction?.action) {
                                                                                const res = alert.confirmAction.action();
                                                                                if (res) {
                                                                                    setLoading(true);
                                                                                    try {
                                                                                        await res;
                                                                                        // eslint-disable-next-line no-empty
                                                                                    } catch (error) {
                                                                                    }
                                                                                    setLoading(false);
                                                                                }
                                                                            }
                                                                            if (onHide) {
                                                                                onHide();
                                                                            }
                                                                        }}>
                                                                        {alert.confirmAction.buttonText}
                                                                    </Button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        </AnimatePresence>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default Alert;
