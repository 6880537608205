import Alert, { IAlertProps } from '@/components/general/Alert';
import React, { createContext, useContext, useEffect, useState } from 'react';

export type AlertContextType = {
    show: (data: IAlertProps) => void
    hide: () => void
};

const AlertContext = createContext<AlertContextType>({
    show: (data: IAlertProps) => {},
    hide: () => {},
});

const useProvideAlert = () => {
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlertProps | null>(null);

    useEffect(() => {
        if (!showAlert && alert) {
            setAlert(null);
        }
    }, [showAlert, alert]);

    const show = (data: IAlertProps) => { setAlert(data); setShowAlert(true); };
    const hide = () => { setShowAlert(false); };

    return { show, hide, alert, showAlert };
};

export const AlertProvider = ({ children }: { children: React.ReactNode; }) => {
    const useAlert = useProvideAlert();
    return (
        <AlertContext.Provider value={useAlert}>
            {children}
            <Alert alert={useAlert.alert} showAlert={useAlert.showAlert} onHide={useAlert.hide} />
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    return useContext(AlertContext);
};

export default AlertProvider;
