import React from 'react';
import { Dialog } from '@headlessui/react';
import type { ComponentProps, ForwardedRef } from 'react';

type DialogProps = ComponentProps<typeof Dialog>;
type OnCloseType = DialogProps['onClose'];

type THDialogWrapperProps = Omit<DialogProps, 'ref'> & {
    innerRef: ForwardedRef<HTMLDivElement>;
};

class THDialogWrapper extends React.Component<THDialogWrapperProps> {
    static instances: { comp: THDialogWrapper, close: OnCloseType }[] = [];

    constructor(props: THDialogWrapperProps) {
        super(props);
        THDialogWrapper.instances.push({ comp: this, close: props.onClose });
    }

    componentWillUnmount(): void {
        THDialogWrapper.instances = THDialogWrapper.instances.filter(({ comp }) => {
            return comp !== this;
        });
    }

    render() {
        const { innerRef, ...rest } = this.props;
        return (
            <Dialog
                {...rest as any}
                ref={innerRef}
                onClose={(value) => {
                    if (this !== THDialogWrapper.instances[THDialogWrapper.instances.length - 1].comp) {
                        return;
                    }
                    THDialogWrapper.instances[THDialogWrapper.instances.length - 1].close(value);
                }} />
        );
    }
}

export default React.forwardRef<HTMLDivElement, Omit<DialogProps, 'ref'>>((props, ref) => <THDialogWrapper innerRef={ref} {...props} />);
